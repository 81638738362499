<template lang="pug">
div
  //- transition(name="fade" mode="out-in")
  span(v-if="!editing") {{ thisInputValue }}
  b-form-textarea(
    v-else
    v-model="thisInputValue"
    :placeholder="placeholder"
    :rows="textareaRow"
    :max-rows="maxTextareaRow"
  )
</template>

<script>
export default {
  name: 'EditableTextareaColumn',

  props: {
    inputValue: {
      type: String,
      default() {
        return '';
      },
    },
    editing: {
      type: Boolean,
      default() {
        return false;
      },
    },
    placeholder: {
      type: String,
      default() {
        return 'Enter something...';
      },
    },
  },

  data(){
    return {
      textareaRow: 3, // 預設顯示的textarea列
      maxTextareaRow: 6, // 最大顯示的textarea列
    };
  },

  computed: {
    thisInputValue: {
      get() {
        return this.inputValue;
      },
      set(value) {
        this.$emit('update:inputValue', value);
      },
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/transition';
</style>
