<template lang="pug">
vue-good-table(
  :columns="columns"
  :rows="rows"
  :theme="theme"
  :pagination-options="{enabled: true, perPageDropdownEnabled: false,}"
)
  template(slot="table-row" slot-scope="props")
    slot(name="table-row" :data="props")
</template>

<script>
// vue-good-table 解構為大駝峰因此這邊使用大駝峰引入套件
import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css';

export default {
  name: 'NormalDataTable',

  components: {
    // vue-good-table 解構為大駝峰因此這邊使用大駝峰引入套件
    VueGoodTable,
  },

  props: {
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
    rows: {
      type: Array,
      default() {
        return [];
      },
    },
    theme: {
      type: String,
      default() {
        // table 樣式參考: https://xaksis.github.io/vue-good-table/guide/style-configuration
        return 'black-rhino';
      },
    },
  },
};
</script>
