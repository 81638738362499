var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [!_vm.editing ? _c('span', [_vm._v(_vm._s(_vm.thisInputValue))]) : _c('b-form-textarea', {
    attrs: {
      "placeholder": _vm.placeholder,
      "rows": _vm.textareaRow,
      "max-rows": _vm.maxTextareaRow
    },
    model: {
      value: _vm.thisInputValue,
      callback: function ($$v) {
        _vm.thisInputValue = $$v;
      },
      expression: "thisInputValue"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }