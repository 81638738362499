<template lang="pug">
div
  .row
    .col-md-4
      label {{ $t('datetimeRange') }}
      select-date-and-time(:datetime.sync="filterParams.datetimeRange" :minuteStep="30")
    .col-md-3
      label {{ $t('vipName') }}
      b-form-input(v-model="filterParams.vipName" :placeholder="`${$t('please')} ${$t('input')} ${$t('vipName')}`")
    .col-md-3
      label {{ $t('className') }}
      b-form-input(v-model="filterParams.className" :placeholder="`${$t('please')} ${$t('input')} ${$t('className')}`")
  .row
    .col-md-3
      br
      button.btn.btn-primary.mr-2.mt-2(@click="search()") {{ $t('go') }}
      button.btn.btn-default.mt-2(@click="reset()") {{ $t('reset') }}
</template>

<script>
import selectDateAndTime from '@/components/filter/selectDateAndTime';
import i18n from '@/views/console/consultant/vipRecords/lang';
export default {
  name: 'VipPerformanceFilter',

  components: {
    selectDateAndTime,
  },

  i18n,

  data() {
    return {
      filterParams: {
        datetimeRange: [],
        vipName: '',
        className: '',
      },
    };
  },

  methods: {
    search(){
    },
    reset(){
    },
  },
};
</script>
