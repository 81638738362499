var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "addStudentPerformanceModal",
      "title": _vm.$t('addStudentPerformance'),
      "header-bg-variant": "primary",
      "ok-title": _vm.$t('submit'),
      "cancel-title": _vm.$t('cancel'),
      "centered": "",
      "button-size": "sm"
    },
    on: {
      "ok": function ($event) {
        return _vm.submit();
      },
      "cancel": function ($event) {
        return _vm.cancel();
      }
    }
  }, [_c('span', [_c('font-awesome-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "user"
    }
  }), _vm._v(_vm._s(`${_vm.$t('name')}: Andy`))], 1), _c('hr'), _c('editableTextareaColumn', {
    attrs: {
      "inputValue": _vm.content,
      "editing": true
    },
    on: {
      "update:inputValue": function ($event) {
        _vm.content = $event;
      },
      "update:input-value": function ($event) {
        _vm.content = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }