<template lang="pug">
b-modal#addStudentPerformanceModal(
  :title="$t('addStudentPerformance')"
  header-bg-variant="primary"
  :ok-title="$t('submit')"
  :cancel-title="$t('cancel')"
  @ok="submit()"
  @cancel="cancel()"
  centered
  button-size="sm"
)
  span
    font-awesome-icon.mr-2(icon="user")
    | {{`${$t('name')}: Andy`}}
  hr
  editableTextareaColumn(:inputValue.sync="content" :editing="true")
</template>

<script>
import editableTextareaColumn from '@/components/consultant/vipRecords/performance/table/editableTextareaColumn/index.vue';
import i18n from '@/views/console/consultant/vipRecords/lang';
export default {
  name: 'AddStudentPerformanceModal',

  components: {
    editableTextareaColumn,
  },

  i18n,

  data() {
    return {
      content: '',
    };
  },

  methods: {
    submit(){
    },

    cancel(){
    },
  },
};
</script>
