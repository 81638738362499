<template lang="pug">
b-modal#performanceHistoryModal(
  :title="$t('vipPerformanceHistory')"
  header-bg-variant="primary"
  :ok-title="$t('ok')"
  :ok-only="true"
  centered
  button-size="sm"
)
  vip-performance-history-filter
  hr
  div.text-center
    timeline(:timelineItems="timelineItems")
  hr
</template>

<script>
import vipPerformanceHistoryFilter from '@/components/consultant/vipRecords/performance/modal/vipPerformanceHistory/filter/index.vue';
import timeline from '@/components/timeline';
export default {
  name: 'PerformanceHistoryModal',

  components: {
    vipPerformanceHistoryFilter,
    timeline,
  },

  props: {
    timelineItems: {
      type: Array,
      default() {
        return [];
      },
    },
  },
};
</script>
