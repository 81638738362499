<template lang="pug">
.wuwow-card
  .wuwow-card-head
    //- Title
    h4.wuwow-card-title {{ $t('vipPerformanceRecords') }}
  .wuwow-card-body
    //- FILTER 1.vip name 2.datetime range 3.class name
    vip-performance-filter
    hr
    //- Table 10per datas
    vip-performance-table

</template>

<script>
import vipPerformanceTable from '@/components/consultant/vipRecords/performance/table/index.vue';
import vipPerformanceFilter from '@/components/consultant/vipRecords/performance/filter/index.vue';
// i18n 需要請顧問翻譯
import i18n from '@/views/console/consultant/vipRecords/lang';
export default {
  name: 'VipPerformanceRecords',

  components: {
    vipPerformanceTable,
    vipPerformanceFilter,
  },

  i18n,
};
</script>
