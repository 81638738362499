export default {
  messages: {
    tw: {
      vipClassRecords: '學員課程紀錄',
      datetimeRange: '日期與時間範圍',
      vipName: '學員名字',
      className: '教室名稱',
      classTime: '上課時間',
      lastTaughtAt: '最後教導時間',
      lastEditedAt: '最後編輯時間',
      classesTaken: '總上課數',
      otherConsultant: '其他顧問',
      vipPerformanceHistory: '學員表現紀錄',
      vipPerformanceRecords: '學員表現紀錄',
      performance: '表現',
      addStudentPerformance: '新增學員表現',
    },
    en: {
      vipClassRecords: 'VIP Class Record',
      datetimeRange: 'Date and Time range',
      vipName: 'Vip Name',
      className: 'Class Name',
      classTime: 'Class Time',
      lastTaughtAt: 'Last Taught At',
      lastEditedAt: 'Last Edited At',
      classesTaken: 'Classes Taken',
      otherConsultant: 'Other Consultants',
      vipPerformanceHistory: 'VIP Performance History',
      vipPerformanceRecords: 'VIP Performance Records',
      performance: 'Performance',
      addStudentPerformance: 'Add Student Performance',
    },
  },
};
