<template lang="pug">
  b-row(align-h="between")
    b-col
      span
        font-awesome-icon.ml-2.mr-2(icon="user")
        | {{ name }}
    b-col
      span.nowrap-content
        font-awesome-icon.ml-2.mr-2(icon="pen")
        | {{ time }}
</template>

<script>
export default {
  name: 'ConsultantTaughtTimeContent',

  props: {
    name: {
      type: String,
      default() {
        return '';
      },
    },
    time: {
      type: String,
      default() {
        return '';
      },
    },
  },
};
</script>
<style scoped>
.nowrap-content{
  white-space: nowrap;
}
</style>
