<template lang="pug">
  .timeline-box
    bootstrap-vue-timeline(
      :items="timelineItems"
      :reverse="true"
      :date-format="dateFormat"
      :variant="styleColor"
      loading
    )
</template>

<script>
import bootstrapVueTimeline from 'bootstrap-vue-timeline';

export default {
  name: 'Timeline',

  components: {
    bootstrapVueTimeline,
  },

  props: {
    timelineItems: {
      type: Array,
      default() {
        return [];
      },
    },
    dateFormat: {
      type: String,
      default() {
        return 'yy-MM-dd HH:mm:ss';
      },
    },
    styleColor: {
      type: String,
      default() {
        return 'primary';
      },
    },
  },

  mounted(){
    this.hideFirstRow();
  },

  methods: {
    // if reverse === true;
    hideFirstRow(){
      const timelineArray = document.getElementsByClassName('align-items-start');
      timelineArray[0].classList.add('d-none');
    },
  },
};
</script>
<style lang="scss">
.timeline-box{
  padding-top: 10px;
  max-height: 500px;
  overflow-y: scroll;
  .list-group-item {
    margin-bottom: 2px !important;
  }
  .item-description{
    border: 1px solid #D3D3D3;
    padding: 5px;
    border-radius: 5px;
    box-shadow: 1px 1px 5px #888888;
    margin: 5px 0;
    font-size: 14px;
    cursor: pointer;
  }
}
/* 隱藏scroll */
.timeline-box::-webkit-scrollbar{
  display: none;
}

</style>
