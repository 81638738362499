var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "wuwow-card"
  }, [_c('div', {
    staticClass: "wuwow-card-head"
  }, [_c('h4', {
    staticClass: "wuwow-card-title"
  }, [_vm._v(_vm._s(_vm.$t('vipPerformanceRecords')))])]), _c('div', {
    staticClass: "wuwow-card-body"
  }, [_c('vip-performance-filter'), _c('hr'), _c('vip-performance-table')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }