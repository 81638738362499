var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "performanceHistoryModal",
      "title": _vm.$t('vipPerformanceHistory'),
      "header-bg-variant": "primary",
      "ok-title": _vm.$t('ok'),
      "ok-only": true,
      "centered": "",
      "button-size": "sm"
    }
  }, [_c('vip-performance-history-filter'), _c('hr'), _c('div', {
    staticClass: "text-center"
  }, [_c('timeline', {
    attrs: {
      "timelineItems": _vm.timelineItems
    }
  })], 1), _c('hr')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }