<template lang="pug">
div
  normal-data-table(
    :columns="columns"
    :rows="rows"
  )
    template(slot="table-row" slot-scope="props")
      div(v-if="props.data.column.field === slotFields.vipName")
        span {{ props.data.row.vipName }}
      div.last-edited-at-box(v-if="props.data.column.field === slotFields.lastEditedAt")
        consultant-edited-time-content(:name="props.data.row.lastEditedAt.currentConsultant.name" :time="props.data.row.lastEditedAt.currentConsultant.time")
        b-button.mt-2(v-b-toggle="`collapse-${props.data.row.id}`" size="sm") {{ $t('otherConsultant') }}
        b-collapse(:id="`collapse-${props.data.row.id}`")
          b-card.mt-1.p-1
            div(v-for="(info, index) in props.data.row.lastEditedAt.otherConsultant" :key="index")
              consultant-edited-time-content(:name="info.name" :time="info.time")
      div(v-if="props.data.column.field === slotFields.performance")
        span {{ props.data.row.performance }}
        //- 在Table中直接編輯學員表現先保留 By Asa
        //- b-row(align-h="between")
          //- b-col(sm="12" md="10")
          //-   editable-textarea-column(:inputValue.sync="props.data.row.performance" :editing=" props.data.row.id === editingId ")
          //- b-col(sm="12" md="2")
          //-     b-button.mt-2(
          //-       v-if="props.data.row.id !== editingId"
          //-       variant="primary"
          //-       size="sm"
          //-       @click="switchEditPerformance(props.data.row.id)"
          //-     )
          //-       font-awesome-icon(icon="pen")
          //-     div(v-if="props.data.row.id === editingId")
          //-       b-button.mt-2(variant="success" size="sm" @click="sendPerformance(props.data.row.performance, props.data.row.id)")
          //-         font-awesome-icon(icon="check")
          //-       b-button.mt-2(variant="danger" size="sm" @click="canclePerformance()")
          //-         font-awesome-icon(icon="ban")
      div(v-if="props.data.column.field === slotFields.operate")
        b-button.mt-2(variant="warning" size="sm" v-b-modal.performanceHistoryModal)
          font-awesome-icon(icon="list")
        b-button.ml-2.mt-2(variant="primary" size="sm" v-b-modal.addStudentPerformanceModal)
          font-awesome-icon(icon="pen")
  vip-performance-history-modal(:timelineItems="timelineItems")
  add-student-performance-modal
</template>

<script>
import consultantEditedTimeContent from '@/components/consultant/vipRecords/performance/table/consultantEditedTimeContent/index.vue';
import editableTextareaColumn from '@/components/consultant/vipRecords/performance/table/editableTextareaColumn/index.vue';
import normalDataTable from '@/components/table/normalDataTable/index.vue';
import vipPerformanceHistoryModal from '@/components/consultant/vipRecords/performance/modal/vipPerformanceHistory/index.vue';
import addStudentPerformanceModal from '@/components/consultant/vipRecords/performance/modal/addStudentPerformance/index.vue';
import i18n from '@/views/console/consultant/vipRecords/lang';

const tableColumnsFields = { vipName: 'vipName', lastEditedAt: 'lastEditedAt', performance: 'performance', operate: 'operate' };

export default {
  name: 'VipPerformanceTable',

  components: {
    editableTextareaColumn,
    consultantEditedTimeContent,
    normalDataTable,
    vipPerformanceHistoryModal,
    addStudentPerformanceModal,
  },

  i18n,

  data() {
    return {
      slotFields: {
        vipName: tableColumnsFields.vipName,
        lastEditedAt: tableColumnsFields.lastEditedAt,
        performance: tableColumnsFields.performance,
        operate: tableColumnsFields.operate,
      },
      columns: [
        {
          label: `Vip ${this.$t('name')}`,
          field: tableColumnsFields.vipName,
          tdClass: 'text-center',
        },
        {
          label: this.$t('lastEditedAt'),
          field: tableColumnsFields.lastEditedAt,
        },
        {
          label: this.$t('performance'),
          field: tableColumnsFields.performance,
        },
        {
          label: this.$t('operate'),
          field: tableColumnsFields.operate,
          tdClass: 'text-center',
        },
      ],
      // TODO 假資料，後續串接API By Asa
      rows: [
        {
          id: 1,
          vipName: 'John',
          lastEditedAt: { // 資料格式需跟後端要求
            currentConsultant: {
              name: 'Jessa Pareno',
              time: '2022-05-07 13:30',
            },
            otherConsultant: [
              { // 時間需排序
                name: 'Alex Junior Cornelius',
                time: '2022-04-30 13:30',
              },
              {
                name: 'Puppy Lia',
                time: '2022-02-28 13:30',
              },
            ],
          },
          performance: '',
        },
        {
          id: 2,
          vipName: 'Jane',
          lastEditedAt: { // 資料格式需跟後端要求
            currentConsultant: {
              name: 'Jessa Pareno',
              time: '2022-05-11 13:30',
            },
            otherConsultant: [
              { // 時間需排序
                name: 'Doris Lacson',
                time: '2022-04-30 13:30',
              },
              {
                name: 'Father Pareno',
                time: '2022-02-28 13:30',
              },
            ],
          },
          performance: 'You are a good student.',
        },
        {
          id: 3,
          vipName: 'Susan',
          lastEditedAt: { // 資料格式需跟後端要求
            currentConsultant: {
              name: 'Jessa Pareno',
              time: '2022-05-07 13:30',
            },
            otherConsultant: [
              { // 時間需排序
                name: 'Niki Lacson',
                time: '2022-04-30 13:30',
              },
              {
                name: 'Fago Pareno',
                time: '2022-02-28 13:30',
              },
            ],
          },
          performance: 'You are a good student.',
        },
        {
          id: 4,
          vipName: 'Chris',
          lastEditedAt: { // 資料格式需跟後端要求
            currentConsultant: {
              name: 'Jessa Pareno',
              time: '2022-05-11 13:30',
            },
            otherConsultant: [
              { // 時間需排序
                name: 'Poop pipi',
                time: '2022-04-30 13:30',
              },
              {
                name: 'Dogo hoha',
                time: '2022-02-28 13:30',
              },
            ],
          },
          performance: 'You are a good student.',
        },
        {
          id: 5,
          vipName: 'Dan',
          lastEditedAt: { // 資料格式需跟後端要求
            currentConsultant: {
              name: 'Jessa Pareno',
              time: '2022-05-11 13:30',
            },
            otherConsultant: [
              { // 時間需排序
                name: 'Like you',
                time: '2022-04-30 13:30',
              },
              {
                name: 'Dont like',
                time: '2022-02-28 13:30',
              },
            ],
          },
          performance: 'You are a good student.',
        },
        {
          id: 6,
          vipName: 'John',
          lastEditedAt: { // 資料格式需跟後端要求
            currentConsultant: {
              name: 'Jessa Pareno',
              time: '2022-05-11 13:30',
            },
            otherConsultant: [
              { // 時間需排序
                name: 'Lala coe',
                time: '2022-04-30 13:30',
              },
              {
                name: 'Ponda chui',
                time: '2022-02-28 13:30',
              },
            ],
          },
          performance: 'You are a good student, but I dont like you at all. Nice!',
        },
      ],
      // TODO 假資料，後續串接API By Asa
      timelineItems: [
        {
          timestamp: Date.parse('2021-01-15T12:10:55.444Z'), // TODO到時候接收後端資料會再另外處理時間
          title: 'David Flint 💬',
          content: 'Everyone in Taiwan studies English. The government provides classrooms, teachers, books, tests, and grades.....but no speaking. Almost everyone I meet knows enough English to talk about many things, but no experience so it is difficult. My advice to students is to talk as much as you can to make all that study worthwhile! Talk as much as you can and make a better future!',
        },
        {
          timestamp: Date.parse('2021-02-20T09:20:36.444Z'),
          title: 'Kai Yang 💬',
          content: 'Hi everyone, my name is Kai. As a bilingual teacher, I am capable of helping students from lower level to upper intermediate.',
        },
        {
          timestamp: Date.parse('2021-04-05T13:20:46.442Z'),
          title: 'Sharon Hsiao 💬',
          content: 'Want to learn English in a fun way? Choose your favorite topics such as travel, news, daily life, technology ...etc to start your class with us. Of course, with the topic you are interested, you will have the motivation to learn English. In the class, don’t be hesitated to speak up and share your opinions. Never worry to make any mistakes in the class because practice makes perfect.',
        },
        {
          timestamp: Date.parse('2022-03-01T07:20:50.446Z'),
          title: 'Stefan Costilow 💬',
          content: 'Never, ever be afraid to push me, argue with me, or present an alternate view. I might be the class teacher, but if you know what you\'re talking about in re the class subject, then I want to hear it! More conversation please!',
        },
        {
          timestamp: Date.parse('2022-04-28T20:20:47.441Z'),
          title: 'Popin Chang 💬',
          content: 'I\'m a South African born Taiwanese who grew up in South Africa and is now residing in Taiwan. I\'m currently a full-time online English Teacher, teaching from children to adults.',
        },

      ],
      editingId: '',
    };
  },

  methods: {
    switchEditPerformance(rowIndex){
      this.editingId = rowIndex;
    },
    sendPerformance(inputValue, rowIndex){
    },
    canclePerformance(){
      this.editingId = '';
    },
  },
};
</script>
<style lang="scss" scoped>
@import '@/styles/transition';
.last-edited-at-box{
  min-width: 400px;
}
.card-body{
  padding: 0;
}
@media only screen and (max-width: 760px) {
  .last-edited-at-box{
    min-width: 100px;
  }
}
</style>
