<template lang="pug">
div
  .row
    .col-md-8
      label {{ $t('datetimeRange') }}
      select-date-and-time(:datetime.sync="filterParams.datetimeRange" :minuteStep="30")
  .row
    .col-md-6
      br
      button.btn.btn-primary.mr-2.mt-2(@click="search()") {{ $t('go') }}
      button.btn.btn-default.mt-2(@click="reset()") {{ $t('reset') }}
</template>

<script>
import selectDateAndTime from '@/components/filter/selectDateAndTime';
import i18n from '@/views/console/consultant/vipRecords/lang';
export default {
  name: 'VipPerformanceHistoryFilter',

  components: {
    selectDateAndTime,
  },

  i18n,

  data() {
    return {
      filterParams: {
        datetimeRange: [],
      },
    };
  },

  methods: {
    search(){
    },
    reset(){
    },
  },
};
</script>
